.errorPage{
    padding: 30px 0;
    .content{
        img{
            display: block;
            margin: 0 auto;
            width: 40%;
        }
        .text{
            text-align: center;
            h1{
                display: block;
                margin-bottom: 0;
                font-family: "Nunito Sans", sans-serif;
                color: rgb(85, 171, 47);
                font-size: 10em;
                line-height: 1em;
                font-weight: bold;
            }
            h2{
                color: rgb(17, 17, 17);
                display: block;
                font-family: "Nunito Sans", sans-serif;
                font-weight: bold;
                font-size: 21px;
                line-height: 21px;
                margin-bottom: 0 0 10px 0;
            }
        }
        @media (min-width: 700px){
            display: flex;
            align-items: center;
            justify-content: space-around;
            .text{
                text-align: left;
                h1{                    
                    font-size: 15em;
                }
                h2{
                    font-size: 42px;
                    line-height: 42px;
                }
            }
        }
        @media (min-width: 900px){
            .text{
                h1{                    
                    font-size: 20em;
                }
            }
        }
    }
}